import { render, staticRenderFns } from "./Setting.vue?vue&type=template&id=67b66fda&scoped=true&"
import script from "./Setting.vue?vue&type=script&lang=js&"
export * from "./Setting.vue?vue&type=script&lang=js&"
import style0 from "./Setting.vue?vue&type=style&index=0&id=67b66fda&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67b66fda",
  null
  
)

export default component.exports